import React from "react"
import Layout from "../components/layout"
import { graphql } from 'gatsby'
import { ArrowCircleRightIcon, ChevronDownIcon } from "@heroicons/react/outline";
import Collapsible from 'react-collapsible';

const News = ({ data }) => (
<Layout>
  <div className="container">
    <div className="main">
    <div id="news">
    <h2>News<span className="text-pink-500">.</span> <span className="h3">| 出演情報</span></h2>
    <h3>今後の出演情報</h3>
    <div className="grid md:grid-cols-2 gap-4">
      {data.upcoming.nodes.length === 0 && (
        <p className="font-bold text-lg">現在、新着情報はありません。</p>
      )}
      {data.upcoming.nodes.map(node => (
        <a href={node.data.url} rel="noopener noreferrer" target="_blank" key={node.recordId}>
        <div className="card">
        <div className="title"><h4>{node.data.title}<span className="text-pink-500 ml-1"><ArrowCircleRightIcon className="h-6 w-6 inline lg:hidden" aria-label="詳細" /></span></h4></div>
        <p>{node.data.text}</p>
        </div>
        </a>
      ))}
    </div>

    <h3 className="mt-10">過去の出演作品</h3>
    <div>
      <div className="rounded-lg bg-gray-50 flex-1 p-8">
      {data.past.nodes.map(node => (
        <div key={node.recordId} className="mb-5 past">
          <h4 className="mb-1">{node.data.short}
          {node.data.link && (
          <a href={node.data.url} rel="noopener noreferrer" target="_blank"><span className="text-pink-500 hover:text-pink-600 active:bg-pink-600 font-bold ml-1 ease-linear transition-all duration-150"><ArrowCircleRightIcon className="h-6 w-6 inline" aria-label="詳細" /></span></a>
          )}
          </h4>
          <p><span className="text-sm text-gray-600 font-bold">{node.data.range}</span><span className="text-xs font-semibold inline-block py-1 px-2 rounded text-gray-700 bg-gray-200 ml-2">{node.data.subcategory}</span></p>
        </div>
      ))}
      </div>
    </div>
{/* 2010 */}
    <Collapsible trigger={["2010年代", <ChevronDownIcon className="h-6 w-6 inline" />]}>
      <div className="rounded-lg bg-gray-50 flex-1 p-8">
      {data.past10.nodes.map(node => (
        <div key={node.recordId} className="mb-5 past">
          <h4 className="mb-1">{node.data.short}
          {node.data.link && (
          <a href={node.data.url} rel="noopener noreferrer" target="_blank"><span className="text-pink-500 hover:text-pink-600 active:bg-pink-600 font-bold ml-1 ease-linear transition-all duration-150"><ArrowCircleRightIcon className="h-6 w-6 inline" aria-label="詳細" /></span></a>
          )}
          </h4>
          <p><span className="text-sm text-gray-600 font-bold">{node.data.range}</span><span className="text-xs font-semibold inline-block py-1 px-2 rounded text-gray-700 bg-gray-200 ml-2">{node.data.subcategory}</span></p>
        </div>
      ))}
      </div>
    </Collapsible>
{/* 2000 */}
    <Collapsible trigger={["2000年代", <ChevronDownIcon className="h-6 w-6 inline" />]}>
      <div className="rounded-lg bg-gray-50 flex-1 p-8">
      {data.past00.nodes.map(node => (
        <div key={node.recordId} className="mb-5 past">
          <h4 className="mb-1">{node.data.short}
          {node.data.link && (
          <a href={node.data.url} rel="noopener noreferrer" target="_blank"><span className="text-pink-500 hover:text-pink-600 active:bg-pink-600 font-bold ml-1 ease-linear transition-all duration-150"><ArrowCircleRightIcon className="h-6 w-6 inline" aria-label="詳細" /></span></a>
          )}
          </h4>
          <p><span className="text-sm text-gray-600 font-bold">{node.data.range}</span><span className="text-xs font-semibold inline-block py-1 px-2 rounded text-gray-700 bg-gray-200 ml-2">{node.data.subcategory}</span></p>
        </div>
      ))}
      </div>
    </Collapsible>
{/* 1990 */}
    <Collapsible trigger={["1990年代", <ChevronDownIcon className="h-6 w-6 inline" />]}>
      <div className="rounded-lg bg-gray-50 flex-1 p-8">
      {data.past90.nodes.map(node => (
        <div key={node.recordId} className="mb-5 past">
          <h4 className="mb-1">{node.data.short}
          {node.data.link && (
          <a href={node.data.url} rel="noopener noreferrer" target="_blank"><span className="text-pink-500 hover:text-pink-600 active:bg-pink-600 font-bold ml-1 ease-linear transition-all duration-150"><ArrowCircleRightIcon className="h-6 w-6 inline" aria-label="詳細" /></span></a>
          )}
          </h4>
          <p><span className="text-sm text-gray-600 font-bold">{node.data.range}</span><span className="text-xs font-semibold inline-block py-1 px-2 rounded text-gray-700 bg-gray-200 ml-2">{node.data.subcategory}</span></p>
        </div>
      ))}
      </div>
    </Collapsible>
    </div>
    </div>
  </div>
</Layout>
)
export default News;

export const query = graphql`
{
  upcoming:allAirtable(
    filter: {data: {category: {eq: "news"}, Publish: {eq: true}, checker: {eq: "yes"}}}
    sort: {order: ASC, fields: data___date}
  ) {
    nodes {
      data {
        title
        url
        text
      }
      recordId
    }
  }

  past:allAirtable(
    filter: {data: {category: {eq: "news"}, Publish: {eq: true}, checker: {eq: "no"}, date: {gte: "2020-01-01"}}}
    sort: {order: DESC, fields: data___date}
  ) {
    nodes {
      data {
        short
        range
        url
        link
        subcategory
      }
      recordId
    }
  }

  past10:allAirtable(
    filter: {data: {category: {eq: "news"}, Publish: {eq: true}, checker: {eq: "no"}, date: {gte: "2010-01-01", lte: "2019-12-31"}}}
    sort: {order: DESC, fields: data___date}
  ) {
    nodes {
      data {
        short
        range
        url
        link
        subcategory
      }
      recordId
    }
  }

  past00:allAirtable(
    filter: {data: {category: {eq: "news"}, Publish: {eq: true}, checker: {eq: "no"}, date: {gte: "2000-01-01", lte: "2009-12-31"}}}
    sort: {order: DESC, fields: data___date}
  ) {
    nodes {
      data {
        short
        range
        url
        link
        subcategory
      }
      recordId
    }
  }

  past90:allAirtable(
    filter: {data: {category: {eq: "news"}, Publish: {eq: true}, checker: {eq: "no"}, date: {gte: "1990-01-01", lte: "1999-12-31"}}}
    sort: {order: DESC, fields: data___date}
  ) {
    nodes {
      data {
        short
        range
        url
        link
        subcategory
      }
      recordId
    }
  }
  
}  
`;